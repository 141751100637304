$light-grey: #F9F5EF;
$grey: #EBE6DD;
$red: #BB4251;
$blue: #8AB5D3;
$dark-red: #681B24;
$black: #3B3F3F;

$font-size-base-xs: 112.5%;
$font-size-base-sm: 125%; // 18px
$font-size-display-1: 3rem; // 54px
$font-size-background-quote: 2.0rem; // 54px
$font-size-background-quote-sm: 2.0rem; // 54px

$line-height-base: 2rem;
$line-height-headings: 3.75rem;
$line-height-background-quote: 2.5rem;
$line-height-background-quote-sm: 2.5rem;

$font-family-headings: 'Source Sans Pro', sans-serif;
$font-family-body: 'Muli', sans-serif;

$cubiz-bezier-highlight: ease-in;

$breakpoint-sm: 700px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;

/////

:root {
  font-size: $font-size-base-xs;
  font-family: $font-family-body;
  color: $black;
  line-height: 2rem;

  @media all and (min-width: $breakpoint-sm) {
    font-size: $font-size-base-sm;
  }
}


body {
  background: $light-grey;
  background: $light-grey;
}

h1, h2, h3 {
  font-family: $font-family-headings;
  line-height: $line-height-headings;
}

a {
  color: $red;
  text-decoration: none;
}

a:hover, a:visited {
  color: $dark-red;
}

//////

.container {
  width: calc(100% - 40px);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.app-container {
  background-color: $light-grey;
}

.page-header {
  position: absolute;
  width: 100%;
  top: calc(80px - 3rem);
  z-index: 1;

  @media all and (min-width: $breakpoint-sm) {
    top: calc(150px - 5rem);
  }
}

// Parallax stuff

.parallax-layer {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.parallax-figure {
  display: none;
  position: absolute;
  top: 0;

  @media all and (min-width: $breakpoint-sm) {
    display: block;
    left: -150px;
    top: 50%;
  }
}


// Headings

.heading-display {
  font-size: $font-size-display-1;
}

.heading-display__highlight {
  position: relative;
  z-index: 1;

  &:after {
    content: " ";
    display: block;
    position: absolute;
    background: $blue;
    z-index: -1;
    width: calc(100% + 10px);
    height: 100%;
    top: 1px;
    left: -5px;
    transform: scaleX(0);
    opacity: 0;
    transition: all 0.4s $cubiz-bezier-highlight;
    transform-origin: left;
  }
}

.heading-display__highlight--active {
  &:after {
    transform: scaleX(1);
    opacity: 1;
  }
}

.background-quote {
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  font-family: $font-family-headings;
  color: $grey;
  font-size: $font-size-background-quote;
  line-height: $line-height-background-quote;
  height: 105vh;

  padding-left: 10vw;

  @media all and (min-width: $breakpoint-sm) {
    padding-left: 25vw;
  }
  @media all and (min-width: $breakpoint-md) {
    padding-left: 30vw;
  }
}


.background-quote-container {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.trails-text {
  position: relative;
  width: 100%;
  height: 30px;
  line-height: 30px;
  will-change: transform, opacity;
  overflow: hidden;
}

.list-trail {
  column-count: 2;
  display: inline-block;
  padding-inline-start: 22px;
  list-style: none;
  width: 100%;
}

.list-trail-item::before {
    content: "\25AA";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: $red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.list-trail-item > span {
  overflow: hidden;
  display: inline-block;
  height: 30px;
}

.layer-heading {
  @media all and (min-width: $breakpoint-sm) {
    padding-left: 25vw;
  }
  @media all and (min-width: $breakpoint-md) {
    padding-left: 25vw;
  }
}

.layer-content {
  padding-left: 10vw;

  @media all and (min-width: $breakpoint-sm) {
    padding-left: 33vw;
  }
  @media all and (min-width: $breakpoint-md) {
    padding-left: 31vw;

  }
}

.dotted-trail {
  content: " ";
  background-image: linear-gradient(black 1px, rgba(255,255,255,0) 0%);
  background-position: right;
  background-size: 2px 15px;
  background-repeat: repeat-y;
  height: 400vh;
  display: block;
  width: 1px;
  position: absolute;
  padding-left: 2.75vw;
  opacity: 0.5;
}

///////


.util-flex {
  display: flex;
}

.util-space-between {
  justify-content: space-between;
}

.util-align-center {
  align-items: center;
}